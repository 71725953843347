import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {DomainGuard} from "../guards/domain.guard";

const routes: Routes = [
    {
        path: '',
        redirectTo: '/cadastro',
        pathMatch: 'full'
    },
    // {
    //     path: 'intro',
    //     loadChildren: () => import('./intro/intro.module').then(m => m.IntroPageModule)
    // },
    // {
    //     path: 'login',
    //     loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
    // },
    {
        path: ':segment',
        loadChildren: () => import('./cadastro/cadastro.module').then(m => m.CadastroPageModule),
        canActivate: [DomainGuard]
    },
    // {
    //     path: 'clubegmais',
    //     loadChildren: () => import('./cadastro-gmais/cadastro.module').then(m => m.CadastroPageModule),
    // },
    // {
    //     path: 'forgot-my-account',
    //     loadChildren: () => import('./forgot-my-account/forgot-my-account.module').then(m => m.ForgotMyAccountModule),
    // },
    {
        path: '',
        loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
